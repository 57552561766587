import {
    LOADING_DATA_NOSOTROS,
    SET_DATA_NOSOTROS,
    SET_ERROR_NOSOTROS
} from '../types'

const initialState = {
    loadingnosotros: false,
    nosotros: [],
    message: '',
    error: ''
}


export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA_NOSOTROS:
            return {
                ...state,
                loadingnosotros: true
            }
        case SET_DATA_NOSOTROS:
            return {
                loadingnosotros: false,
                ...action.payload
            }
        case SET_ERROR_NOSOTROS:
            return {
                loadingnosotros: false,
                ...action.payload
            }
        default: return state
    }
}