import React from 'react'
import { browserHistory } from 'react-router'

import fondo from '../../images/fondos/nosotros.png'

class Nosotros extends React.Component {
    render() {

        return (
            <div id='nosotros' className='nosotros' style={{ backgroundImage: 'url(' + fondo + ')' }}>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                        <div className='wrapper_nosotros_quees'>
                            <div className='wrapper_informacion_quees'>
                                <div className='wrapper_nosotros_titulo'>
                                    <h2>¿QUIENES SOMOS?</h2>
                                    <div className='separador' />
                                </div>
                                <div className='wrapper_nosotros_descripcion'>
                                    <h4>Somos una empresa dedicada a brindar soluciones para la protección de cultivos agrícolas a nivel nacional suministrando e instalando mallas anti-pájaros
                                        <span><strong><a href='/acerca-de-nosotros'> [ver mas]</a></strong></span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                        <div className='wrapper_nosotros_somos'>
                            <div className='wrapper_informacion_somos'>
                                <div className='wrapper_nosotros_titulo'>
                                    <h2 style={{color: 'transparent', cursor: 'default'}}>¿ACERCA DE NOSOTROS?</h2>
                                    <div className='separador' style={{ borderBottom: 'none'}} />
                                </div>
                                <div className='wrapper_nosotros_descripcion'>
                                    <h4>En atención a la necesidad de nuestros clientes hemos incluido la división de obras de infraestructura y servicios generales con personal altamente calificado y amplia experiencia.
                                        <span><strong><a href='/acerca-de-nosotros'> [ver mas]</a></strong></span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Nosotros