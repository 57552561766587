import React from 'react'


class GrupoVideos extends React.Component {
    render() {
        let index = this.props.index

        const grupocero = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Famarra-alambre-anclaje%2Fcero.mp4?alt=media&token=c47cf867-8feb-4a82-afed-05aa0d2dce91' }
        ]

        const grupouno = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fpostes-madera-eucalipto%2Fcero.mp4?alt=media&token=f84e2248-0cb6-4931-ac1b-4baff2833753' }
        ]

        const grupodos = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Falambre-galvanizado%2Fcero.mp4?alt=media&token=1f1e8c47-1e63-4995-8658-b0ef732bd028' }
        ]

        const grupotres = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmalla-cortaviento%2Fcero.mp4?alt=media&token=36b3c9f2-4a53-4d37-a4e7-7469c5a39fe6' }
        ]

        const grupocuatro = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmalla-raschel%2Fcero.mp4?alt=media&token=61e39319-e35f-4857-b6ce-67338186abb0' },
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmalla-raschel%2Funo.mp4?alt=media&token=61e39319-e35f-4857-b6ce-67338186abb0' },
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmalla-raschel%2Fdos.mp4?alt=media&token=61e39319-e35f-4857-b6ce-67338186abb0' },
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmalla-raschel%2Ftres.mp4?alt=media&token=61e39319-e35f-4857-b6ce-67338186abb0' },
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmalla-raschel%2Fcuatro.mp4?alt=media&token=61e39319-e35f-4857-b6ce-67338186abb0' }
        ]

        const grupocinco = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fpostes-madera%2Fcero.mp4?alt=media&token=d390e7b4-5d58-4740-a6a0-3b719ac47cb7' }
        ]

        const gruposeis = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmalla-antipajaro%2Fcero.mp4?alt=media&token=cdff964e-456d-4044-834a-735024cf0882' }
        ]

        const gruposiete = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fmojado-hoyado-suelo%2Fcero.mp4?alt=media&token=d8445477-6012-4c8b-aa49-2cb175e26ae6' }
        ]

        const grupoocho = [
            { video: 'https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/videos%2Fotros%2Fcero.mp4?alt=media&token=8ad53dda-77f5-4549-9914-cb0862d302dc' }
        ]

        return (
            <div className='wrapper_galeria_imagenes'>
                <div className='imagenes_contenido'>
                    <div className='wrapper_imagenes_contenido'>
                        <div className='wrapper_grid_5_columns'>
                            <div className='row'>
                                {
                                    index === 0 ? (
                                        grupocero.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4' />
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : index === 1 ? (
                                        grupouno.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : index === 2 ? (
                                        grupodos.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : index === 3 ? (
                                        grupotres.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : index === 4 ? (
                                        grupocuatro.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : index === 5 ? (
                                        grupocinco.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : index === 6 ? (
                                        gruposeis.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : index === 7 ? (
                                        gruposiete.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        grupoocho.map((video, index) => {
                                            return (
                                                <div className='video_fullscreen'>
                                                    <div className='video_image_hover'>
                                                        <video controls>
                                                            <source src={video.video} type='video/mp4'/>
                                                        </video>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GrupoVideos