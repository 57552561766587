import {
    LOADING_DATA_SERVICIOS,
    SET_DATA_SERVICIOS,
    SET_ERROR_SERVICIOS
} from '../types'

const initialState = {
    loadingservicios: false,
    servicios: [],
    message: '',
    error: ''
}


export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_DATA_SERVICIOS:
            return {
                ...state,
                loadingservicios: true
            }
        case SET_DATA_SERVICIOS:
            return {
                loadingservicios: false,
                ...action.payload
            }
        case SET_ERROR_SERVICIOS:
            return {
                loadingservicios: false,
                ...action.payload
            }
        default: return state
    }
}