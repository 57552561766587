import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import { connect } from 'react-redux'
import {
    obtenerWebDatosAdmin
} from '../redux/actions/dataActions'

import Slider from './dashboard/slider'
import Servicios from './dashboard/servicios'
import Nosotros from './dashboard/nosotros'
import Proyectos from './dashboard/proyectos'
import Contacto from './dashboard/contacto'

class Dashboard extends React.Component {
    componentWillMount (){
        this.props.obtenerWebDatosAdmin()
    }

    render() {
        return (
            <div className='dashboard'>
                <Slider/>
                <Servicios/>
                <Nosotros/>
                <Proyectos/>
                <Contacto/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

Dashboard.propTypes = {
    obtenerWebDatosAdmin: PropTypes.func.isRequired
}

const mapActionsToProps = {
    obtenerWebDatosAdmin
}

export default connect(mapStateToProps, mapActionsToProps)(Dashboard)