import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import slidersReducer from './reducers/slidersReducer'
import serviciosReducer from './reducers/serviciosReducer'
import nosotrosReducer from './reducers/nosotrosReducer'
import proyectosReducer from './reducers/proyectosReducer'
import adminReducer from './reducers/adminReducer'

const initialState = {}

const middleware = [
    thunk
]

const reducers = combineReducers({
    sliders: slidersReducer,
    admin: adminReducer,
    nosotros: nosotrosReducer,
    proyectos: proyectosReducer,
    servicios: serviciosReducer
})

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

export default store