import React from 'react'
import { browserHistory } from 'react-router'

import fondo from '../images/fondos/instalamos.png'
import logo from '../images/footer.png'

import facebook from '../images/redes/facebook.png'
import instagram from '../images/redes/instagram.png'
import linkedin from '../images/redes/linkedin.png'
import twitter from '../images/redes/twitter.png'

import location from '../images/contact/location.png'
import mail from '../images/contact/mail.png'
import phone from '../images/contact/phone.png'
import clock from '../images/contact/clock.png'

class Footer extends React.Component{

    rederigirservicios (e){
        e.preventDefault ()

        let id = e.target.id

        browserHistory.push (id)
    }

    render (){ 
        return (
            <div id='footer' className='footer' style={{ backgroundImage: 'url(' + fondo + ')' }}>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3'>
                        <div className='wrapper_footer_qoxecha'>
                            <div className='wrapper_footer_logo'>
                                <img src={logo} alt='logo'/>
                            </div>
                            <div className='wrapper_footer_redes'>
                                <div className='wrapper_footer_social'>
                                    <img src={facebook} alt='facebook'/>
                                </div>
                                <div className='wrapper_footer_social'>
                                    <img src={instagram} alt='instagramo' />
                                </div>
                                <div className='wrapper_footer_social'>
                                    <img src={linkedin} alt='linkedino' />
                                </div>
                                <div className='wrapper_footer_social'>
                                    <img src={twitter} alt='twittero' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3'>
                        <div className='wrapper_footer_instalamos'>
                            <div className='wrapper_footer_titulo'>
                                <h4>PROYECTOS</h4>
                                <div className='separador' />
                            </div>
                            <div className='wrapper_footer_listado'>
                                <ul className='list-group'>
                                    <li className='list-group-item' onClick={this.rederigirservicios.bind (this)} 
                                        id='/servicios/mallas-protección-cultivos/antipajaro'>
                                        Malla Antipájaros
                                    </li>
                                    <li className='list-group-item' onClick={this.rederigirservicios.bind (this)} 
                                        id='/servicios/mallas-protección-cultivos/cortaviento'>
                                        Malla cortavientos
                                    </li>
                                    <li className='list-group-item' onClick={this.rederigirservicios.bind (this)} 
                                        id='/servicios/mallas-protección-cultivos/anti-granizo'>
                                        Malla anti granizo
                                    </li>
                                    <li className='list-group-item' onClick={this.rederigirservicios.bind (this)} 
                                        id='/servicios/mallas-protección-cultivos/raschel'>
                                        Malla raschel
                                    </li>
                                    <li className='list-group-item' onClick={this.rederigirservicios.bind (this)} 
                                        id='/servicios/mallas-protección-cultivos/mixtas'>
                                        Malas mixtas
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3'>
                        <div className='wrapper_footer_menu'>
                            <div className='wrapper_footer_titulo'>
                                <h4>MENU</h4>
                                <div className='separador' />
                            </div>
                            <div className='wrapper_footer_listado'>
                                <ul className='list-group'>
                                    <li className='list-group-item'>
                                        <a href='/acerca-de-nosotros'>Acerca de nosotros</a>
                                    </li>
                                    <li className='list-group-item'>
                                        <a href='/mallas-protección-cultivos'>Servicios</a>
                                    </li>
                                    <li className='list-group-item'>
                                        <a href='/galería/vídeos'>Galería</a>
                                    </li>
                                    <li className='list-group-item'>
                                        <a href='/#contacto'>Contácto</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3'>
                        <div className='wrapper_footer_contacto'>
                            <div className='wrapper_footer_titulo'>
                                <h4>PONTE EN CONTACTO</h4>
                                <div className='separador' />
                            </div>
                            <div className='wrapper_footer_listado'>
                                <ul className='list-group'>
                                    <li className='list-group-item'>
                                        <img src={location} alt='ubicacion'/> Calle XXXX XXXX
                                    </li>
                                    <li className='list-group-item'>
                                        <img src={phone} alt='phone' /> 000-0000
                                    </li>
                                    <li className='list-group-item'>
                                        <img src={mail} alt='mail' /> correo@qoxecha.com
                                    </li>
                                    <li className='list-group-item'>
                                        <img src={clock} alt='clock' /> 00:00 - 00:00hrs
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer