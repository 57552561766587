import React from 'react'

class FooterDeveloper extends React.Component {
    render() {
        return (
            <div className='footer-developer'>
                <div className='wrapper_copy_right'>
                    Copyright © 2021 Developed by Developer Ideas
                </div>
            </div>
        )
    }
}

export default FooterDeveloper