export const LOADING_DATA_ADMIN = 'LOADING_DATA_ADMIN'
export const SET_DATA_ADMIN = 'SET_DATA_ADMIN'
export const SET_ERROR_ADMIN = 'SET_ERROR_ADMIN'

export const LOADING_DATA_SLIDERS = 'LOADING_DATA_SLIDERS'
export const SET_DATA_SLIDERS = 'SET_DATA_SLIDERS'
export const SET_ERROR_SLIDERS = 'SET_ERROR_SLIDERS'

export const LOADING_DATA_SERVICIOS = 'LOADING_DATA_SERVICIOS'
export const SET_DATA_SERVICIOS = 'SET_DATA_SERVICIOS'
export const SET_ERROR_SERVICIOS = 'SET_ERROR_SERVICIOS'

export const LOADING_DATA_NOSOTROS = 'LOADING_DATA_NOSOTROS'
export const SET_DATA_NOSOTROS = 'SET_DATA_NOSOTROS'
export const SET_ERROR_NOSOTROS = 'SET_ERROR_NOSOTROS'

export const LOADING_DATA_PROYECTOS = 'LOADING_DATA_PROYECTOS'
export const SET_DATA_PROYECTOS = 'SET_DATA_PROYECTOS'
export const SET_ERROR_PROYECTOS = 'SET_ERROR_PROYECTOS'