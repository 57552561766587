import React from 'react'

import Banner from './banner'
import Navegador from './navegador'
import Footer from './footer'
import FooterDeveloper from './footerdeveloper'

class QoxechaApp extends React.Component{
    render() {
        let childs = this.props.children
            && React.cloneElement(this.props.children)

        return (
            <div className='qoxechaapp'>
                <Banner />
                <Navegador />
                {childs}
                <Footer />
                <FooterDeveloper />
            </div>
        )
    }
}

export default QoxechaApp