import React from 'react'

class Slider extends React.Component {
    render() {
        
        return (
            <div id='slider' className='slider'>
                <div className='wrapper_slider'>
                    <div id='carouselSlider' className='carousel slide' data-ride='carousel'>
                        <div className='carousel-inner'>
                            <div data-interval='2000' className='carousel-item active'>
                                <div className='background' style={{
                                    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/sliders%2Ffondo02.png?alt=media&token=c26af26b-9a9d-4b3d-b696-05423215d157)',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    height: '100vh'
                                }}>
                                    <div className={`wrapper_slider_info_0`}>

                                        <div className='wrapper_slider_info_titulo'>
                                            <div className='wrapper_slider_titulo'>
                                                <p>SOLUCIONES PARA LA</p>
                                                <br></br>
                                                <p>PROTECCIÓN DE CULTIVOS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div data-interval='2000' className='carousel-item'>
                                <div className='background' style={{
                                    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/sliders%2Ffondo03.png?alt=media&token=f04802bd-e303-4912-a1d9-8a22e0c61dd4)',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    height: '100vh'
                                }}>
                                    <div className={`wrapper_slider_info_0`}>
                                        <div className='wrapper_slider_info_titulo'>
                                            <div className='wrapper_slider_titulo'>
                                                <p>POR UNA AGRICULTURA</p>
                                                <br></br>
                                                <p>SOTENIBLE</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div data-interval='2000' className='carousel-item'>
                                <div className='background' style={{
                                    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/sliders%2Ffondo00.png?alt=media&token=ce5c5852-a252-41dd-84cc-8e192a9272d1)',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    height: '100vh'
                                }}>
                                    <div className={`wrapper_slider_info_0`}>

                                        <div className='wrapper_slider_info_titulo'>
                                            <div className='wrapper_slider_titulo'>
                                                <p>SOMMOS UN EQUIPO</p>
                                                <br></br>
                                                <p>DINÁMICO</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div data-interval='2000' className='carousel-item'>
                                <div className='background' style={{
                                    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/sliders%2Ffondo01.png?alt=media&token=fb5e77f3-6c38-4ecb-ac9f-affdb196ac14)',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    height: '100vh'
                                }}>
                                    <div className={`wrapper_slider_info_0`}>

                                        <div className='wrapper_slider_info_titulo'>
                                            <div className='wrapper_slider_titulo primero'>
                                                <p>USO DE LA TECNOLOGÍA</p>
                                                <br></br>
                                                <p>MÁS MODERNA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselSlider" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselSlider" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Slider