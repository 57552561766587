import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import { connect } from 'react-redux'
import {
} from '../redux/actions/dataActions'

import phone from '../images/iconos/phone_green.png'
import logo from '../images/logo.png'

class Banner extends React.Component{

    render (){
        return (
            <div id='banner' className='banner'>
                <div className='row izq der'>
                    <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 izq'>
                        <div className='wrapper_banner_logo'>
                            <a href='/'>
                                <img src={logo} alt='LOGO Q OXECHA'/>
                            </a>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 der'>
                        <div className='wrapper_banner_contacto'>
                            <div className='wrapper_banner_contacto_telefono'>
                                <img src={phone} alt='telefono' />
                            </div>
                            <div className='wrapper_banner_contacto_texto'>
                                <p className='telefono'>(01) 444 4444</p>
                                <p className='correo'>correo@qoxecha.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    admin: state.admin
})

Banner.propTypes = {
    admin: PropTypes.object.isRequired
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(Banner)