import React from 'react'

class Mallas extends React.Component{

    componentWillMount(){
        let tipo = this.props.params.malla

        console.log (tipo)
        
        window.scrollTo(0, 0)
    }

    render (){
        return (
            <div className='nosotros'>
                <div className='wrapper_nosotros'>
                    <div className='entry_content'>
                        <div className='wrapper_entry_content'>
                            <div className='wrapper_nosotros_panel'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='wrapper_heading'>
                                            <h2>INSTALCION DE MALLAS: </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className='row' id='antipajaro' style={{ marginBottom: '32px' }}>
                                    <div className='col-md-4' style={{alignSelf: 'center'}}>
                                        <div className='wrapper_sub_heading' style={{ borderBottom: 'none' }}>
                                            <h2><strong>ANTIPAJARO:</strong></h2>
                                        </div>
                                        <div className='wrapper_descripcion'>
                                            <p>Para la protección contra la acción de las aves de todo tipo de cultivos, mejorado el rendimiento de las cosechas y evitar las mermas.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <div className='col-md-4' style={{paddingLeft: '0', paddingRight: '0'}}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen00.png?alt=media&token=bf28a4b6-813b-4fed-bd3a-a90a7b86e928' alt='imagen00' style={{ width: '100%', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px'  }} />
                                            </div>
                                            <div className='col-md-4' style={{paddingLeft: '0', paddingRight: '0'}}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen01.png?alt=media&token=aad77333-4867-476d-8a2c-efc350de28a7' alt='imagen01' style={{ width: '100%' }} />
                                            </div>
                                            <div className='col-md-4' style={{paddingLeft: '0', paddingRight: '0'}}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen02.png?alt=media&token=f3e61411-01d2-4e07-8681-071ce21a558b' alt='imagen02' style={{ width: '100%', borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row' id='cortaviento' style={{marginBottom: '32px' }}>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <div className='col-md-4' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen03.png?alt=media&token=9d852cc8-2f9a-4573-a6f8-164d273b1abe' alt='imagen03' style={{ width: '100%', height: '202.60px', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }} />
                                            </div>
                                            <div className='col-md-4' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen04.jpg?alt=media&token=bdc4a89e-7ea4-46b2-9dff-0468edfb20a6' alt='imagen04' style={{ width: '100%', height: '202.60px' }} />
                                            </div>
                                            <div className='col-md-4' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen05.jpg?alt=media&token=54d45b52-9dd1-4267-8d81-d357da377558' alt='imagen05' style={{ width: '100%', height: '202.60px', borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4' style={{ alignSelf: 'center' }}>
                                        <div className='wrapper_sub_heading' style={{ borderBottom: 'none' }}>
                                            <h2><strong>CORTAVIENTO:</strong></h2>
                                        </div>
                                        <div className='wrapper_descripcion'>
                                            <p>Para la protección de los cultivos contra los fuertes vientos y arena salobre.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row' id='anti-granizo' style={{ marginBottom: '32px' }}>
                                    <div className='col-md-4' style={{ alignSelf: 'center' }}>
                                        <div className='wrapper_sub_heading' style={{ borderBottom: 'none' }}>
                                            <h2><strong>ANTI GRANIZO:</strong></h2>
                                        </div>
                                        <div className='wrapper_descripcion'>
                                            <p>Usado para la protección de todo tipo de cultivos. Reduce la radiación solar directa sobre las planta y se crea un microclima consiguiendo un mejor crecimiento de los cultivos.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <div className='col-md-5' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen06.jpg?alt=media&token=e06b810a-59cd-453a-b2fa-c7264e066184' alt='imagen06' style={{ width: '100%', height: '222.55px', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }} />
                                            </div>
                                            <div className='col-md-7' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen07.jpg?alt=media&token=59269cad-4474-4bac-b8dc-c9190391e4f5' alt='imagen07' style={{ width: '100%', height: '222.55px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row' id='raschel' style={{ marginBottom: '32px' }}>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <div className='col-md-7' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen08.jpg?alt=media&token=799d5ffd-222e-4da9-bf3e-848c008bda03' alt='imagen08' style={{ width: '100%', height: '202.60px', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }} />
                                            </div>
                                            <div className='col-md-5' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen09.jpg?alt=media&token=cedb803f-81f3-4fcb-80f5-38b887573abb' alt='imagen09' style={{ width: '100%', height: '202.60px' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4' style={{ alignSelf: 'center' }}>
                                        <div className='wrapper_sub_heading' style={{ borderBottom: 'none' }}>
                                            <h2><strong>RASCHEL:</strong></h2>
                                        </div>
                                        <div className='wrapper_descripcion'>
                                            <p>Para disminuir el paso de la luz y proteger de los vientos no muy fuertes.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='row' id='mixtas' style={{ marginBottom: '32px' }}>
                                    <div className='col-md-4' style={{ alignSelf: 'center' }}>
                                        <div className='wrapper_sub_heading' style={{ borderBottom: 'none' }}>
                                            <h2><strong>MIXTAS:</strong></h2>
                                        </div>
                                        <div className='wrapper_descripcion'>
                                            <p>Para proteger los reservorios contra las impurezas que traen los fuertes vientos.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            <div className='col-md-6' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen10.png?alt=media&token=b7274fce-e921-45d0-9ec8-460945bb1f6c' alt='imagen10' style={{ width: '100%', height: '222.55px', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }} />
                                            </div>
                                            <div className='col-md-6' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen11.png?alt=media&token=2a7e08be-15e2-482c-bedb-33e8d441cb68' alt='imagen11' style={{ width: '100%', height: '222.55px' }} />
                                            </div>
                                            <div className='col-md-12' style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/mallas%2Fimagen12.png?alt=media&token=63a8865f-fc86-47af-b480-58575ffb725a' alt='imagen12' style={{ width: '100%', height: '222.55px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Mallas