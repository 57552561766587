import React from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import { connect } from 'react-redux'
import {
} from '../../redux/actions/dataActions'

import fondo from '../../images/fondos/contacto.PNG'

class Contacto extends React.Component {
    constructor (props){
        super (props)
        this.state = {
            nombres: '',
            correo: '',
            telefono: '',
            mensaje: ''
        }
    }

    handleInput(e) {
        e.preventDefault()

        let id = e.target.id
        let value = e.target.value
        this.setState (update (this.state, {
            [id]: {$set: value}
        }))
    }

    enviarmensaje (e){
        e.preventDefault ()
    }
    
    render() { 
        return (
            <div id='contacto' className='contacto' style={{
                backgroundImage: 'url(' + fondo + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className='row'>
                    <div className='col-0 col-sm-0 col-md-0 col-lg-4 col-xl-6'>
                        <div className='wrapper_contacto_imagen'>

                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6'>
                        <div className='wrapper_contacto'>
                            <div className='wrapper_contacto_titulo'>
                                <h2>PONTE EN CONTACTO</h2>
                                <div className='separador' />
                            </div>
                            <div className='wrapper_contacto_formulario_borde'>
                                <div className='wrapper_contacto_formulario'>
                                    <form onSubmit={this.enviarmensaje.bind(this)}>
                                        <div className='form-group'>
                                            <input type='text' className='form-control' id='nombres'
                                                value={this.state.nombres} placeholder='Nombres y apellidos'
                                                onChange={this.handleInput.bind(this)} />
                                        </div>
                                        <div className='form-group'>
                                            <input type='email' className='form-control' id='correo'
                                                value={this.state.correo} placeholder='Correo electrónico'
                                                onChange={this.handleInput.bind(this)} />
                                        </div>
                                        <div className='form-group'>
                                            <input type='number' className='form-control' id='telefono'
                                                value={this.state.telefono} placeholder='Teléfono'
                                                onChange={this.handleInput.bind(this)} />
                                        </div>
                                        <div className='form-group'>
                                            <textarea className='form-control' id='mensaje' rows='4'
                                                value={this.state.mensaje} placeholder='Mensaje'
                                                onChange={this.handleInput.bind(this)} />
                                        </div>
                                        <button className='btn' onClick={this.enviarmensaje.bind(this)}>ENVIAR MENSAJE</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    admin: state.admin
})

Contacto.propTypes = {
    admin: PropTypes.object.isRequired
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(Contacto)