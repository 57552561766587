import React from 'react'

class Nosotros extends React.Component{

    render() {

        return (
            <div className='nosotros'>
                <div className='wrapper_nosotros'>
                    <div className='entry_content'>
                        <div className='wrapper_entry_content'>
                            <div className='wrapper_nosotros_panel'>
                                <div className='wrapper_heading'>
                                    <h2>ACERCA DE NOSOTROS</h2>
                                </div>
                                <div className='wrapper_descripcion'>
                                    <p>Somos una empresa dedicada a brindar soluciones para la protección de cultivos agrícolas a nivel nacional suministrando e instalando mallas anti-pájaros, cortaviento, anti-áfido, anti-polinización, anti-granizo, raschel, entre otras.</p>
                                    <br></br>
                                    <p>Representamos en forma exclusiva para el Perú a los siguientes fabricantes de mallas:</p>
                                    <br></br>
                                    <div className='row'>
                                        <div className='col-md-6 izq'>
                                            <p><strong>COTEXA ALCALAINA,</strong> fabricantes de mallas para la agricultura durante 40 años con presencia en 39 países a nivel mundial, ubicada en la ciudad de Almeria – España.</p>
                                        </div>
                                        <div className='col-md-6 der'>
                                            <p><strong>SOMBRAS Y PROTECCIONES,</strong> fabricantes de mallas para la agricultura y construcción con 14 años en el rubro, ubicada en la ciudad Guanajuato – México.</p>
                                        </div>
                                    </div>
                                    <br></br>
                                    <p>En atención a la necesidad de nuestros clientes hemos incluido la división de obras de infraestructura y servicios generales con personal altamente calificado y amplia experiencia.</p>
                                    <br></br>
                                    <p>Somos un equipo dinámico enfocado en sobrepasar las expectativas de nuestros clientes para lo cual priorizamos nuestras propuestas al detalle, nos involucramos con el cumplimiento de nuestros plazos de ejecución y nos esforzamos por desarrollar un trato personalizado a nuestros clientes.</p>
                                </div>
                                <div className='wrapper_imagenes'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/imagenes%2Fnosotros%2Fimagenuno610x462.PNG?alt=media&token=921a3287-7f44-4e22-b7a9-660a5bd1f491' alt='imagen' />
                                        </div>
                                        <div className='col-md-6'>
                                            <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/imagenes%2Fnosotros%2Fimagencero610x462.png?alt=media&token=73acc069-67a1-4ac1-937d-9098b2787d52' alt='imagen' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Nosotros