import React from 'react'

class Servicios extends React.Component {

    render() {
        return (
            <div className='servicios'>
                <div className='wrapper_servicios'>
                    <div className='wrapper_servicios_descripcion'>
                        
                    </div>
                    <div className='wrapper_servicios_cards'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                < div className='card servicio' >
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/servicios%2Fservicio00.jpg?alt=media&token=700e52e6-9c7d-48e7-915e-aa8c0e9dc1c6' className='card-img-top' alt='servicio' style={{ width: '366.33px', height: '244.22px' }} />
                                    </div>
                                    <div className='card-body'>
                                        <h4 className='card-title'>PROCESO DE SOLUCIONES</h4>
                                        <p className="card-text">Brindamos soluciones integrales para el sector agrícola que incluye el suministro e instalación de mallas para protección de los diferentes cultivos con productos certificados de óptima calidad.</p>
                                        <h6><a href="" className="btn"></a></h6>
                                    </div>
                                </div >
                            </div>
                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                <div className='card servicio'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/servicios%2Fservicio02.jpg?alt=media&token=037a50dd-1afa-46b1-878c-1bb76746b7df' className='card-img-top' alt='servicio' style={{ width: '366.33px', height: '244.22px' }} />
                                    </div>
                                    <div className='card-body'>
                                        <h4 className='card-title'>INOVACION</h4>
                                        <p className="card-text">Nos desarrollamos a nivel nacional en los distintos departamentos de Perú. Hemos sabido desarrollar soluciones adaptadas a las necesidades de los tipos cultivos y condiciones meteorológicas.</p>
                                        <h6><a href="" className="btn"></a></h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                <div className='card servicio'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/servicios%2Fservicio01.jpg?alt=media&token=f7d735e8-3c3f-428e-bb61-2852a391d0f8' className='card-img-top' alt='servicio' style={{ width: '366.33px', height: '244.22px' }} />
                                    </div>
                                    <div className='card-body'>
                                        <h4 className='card-title'>PROYECCION NACIONAL</h4>
                                        <p className="card-text">Nos desarrollamos a nivel nacional en los distintos departamentos de Perú. Hemos sabido desarrollar soluciones adaptadas a las necesidades de los tipos cultivos y condiciones meteorológicas.</p>
                                        <h6><a href="" className="btn"></a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Servicios