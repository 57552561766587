import React from 'react'

import search from '../../images/iconos/search_white.png'

class Proyectos extends React.Component {

    render() {

        return (
            <div className='instalamos' style={{ backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Finstalamos.png?alt=media&token=9f8e2072-4b1e-4cd8-8dc5-35659d052c61)'}}>
                <div className='wrapper_instalamos'>
                    <div className='wrapper_instalamos_titulo'>
                        <h2>Nuestros proyectos</h2>
                        <div className='separador'/>
                    </div>
                    <div className='wrapper_instalamos_cards'>
                            <div className='row'>
                                <div className='card instalacion'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Fproyecto00.png?alt=media&token=b47be1e2-2720-4ba8-bdab-4d2789c6e950' className='card-img-top' alt='instalacion' />
                                        <span className='wrapper_search'>
                                            <span className='wrapper_search_caption_table'>
                                                <span className='wrapper_search_caption'>
                                                    <span className='wrapper_search_image'>
                                                        <img src={search} alt='buscar' />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='card instalacion'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Fproyecto01.png?alt=media&token=03ad08bc-6e05-4dee-904f-b1d55dd669f7' className='card-img-top' alt='instalacion' />
                                        <span className='wrapper_search'>
                                            <span className='wrapper_search_caption_table'>
                                                <span className='wrapper_search_caption'>
                                                    <span className='wrapper_search_image'>
                                                        <img src={search} alt='buscar' />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='card instalacion'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Fproyecto02.png?alt=media&token=8e7e1c43-ee64-4b15-bb8f-6d8febf79235' className='card-img-top' alt='instalacion' />
                                        <span className='wrapper_search'>
                                            <span className='wrapper_search_caption_table'>
                                                <span className='wrapper_search_caption'>
                                                    <span className='wrapper_search_image'>
                                                        <img src={search} alt='buscar' />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='card instalacion'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Fproyecto03.png?alt=media&token=2c987a51-a543-4806-b2f4-2fe54e230f9d' className='card-img-top' alt='instalacion' />
                                        <span className='wrapper_search'>
                                            <span className='wrapper_search_caption_table'>
                                                <span className='wrapper_search_caption'>
                                                    <span className='wrapper_search_image'>
                                                        <img src={search} alt='buscar' />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='card instalacion'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Fproyecto04.png?alt=media&token=2d571756-184d-4f74-9fbe-b83a16859e20' className='card-img-top' alt='instalacion' />
                                        <span className='wrapper_search'>
                                            <span className='wrapper_search_caption_table'>
                                                <span className='wrapper_search_caption'>
                                                    <span className='wrapper_search_image'>
                                                        <img src={search} alt='buscar' />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='card instalacion'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Fproyecto05.png?alt=media&token=12d7b3bd-b814-4db3-b786-05b7da08c48a' className='card-img-top' alt='instalacion' />
                                        <span className='wrapper_search'>
                                            <span className='wrapper_search_caption_table'>
                                                <span className='wrapper_search_caption'>
                                                    <span className='wrapper_search_image'>
                                                        <img src={search} alt='buscar' />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className='card instalacion'>
                                    <div className='wrapper_card_img'>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/qoxecha-web.appspot.com/o/proyectos%2Fproyecto06.png?alt=media&token=7a691195-b7a2-4517-b1f6-a6a93e198aaf' className='card-img-top' alt='instalacion' />
                                        <span className='wrapper_search'>
                                            <span className='wrapper_search_caption_table'>
                                                <span className='wrapper_search_caption'>
                                                    <span className='wrapper_search_image'>
                                                        <img src={search} alt='buscar' />
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Proyectos