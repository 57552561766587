import React from 'react'
import update from 'immutability-helper'
import {browserHistory} from 'react-router'

import menu from '../images/iconos/menu.png'

class Navegador extends React.Component {
    constructor(props){
        super (props)
        this.state={
            tab: 'inicio'
        }
    }
    
    rederigir (e){
        e.preventDefault ()
        let id = e.target.id
        
        this.setState (update (this.state, {
            tab: {$set: id}
        }))

        if (id === 'inicio') {
            browserHistory.push('')            
        }else{
            browserHistory.push (id)
        }
    }

    render() {
        return (
            <div id='navegador' className='navegador'>
                <div className='wrapper_navegador_menu'>
                    <nav className="navbar navbar-expand-lg navbar bg">
                        <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#menunav'
                            aria-controls='menunav' aria-expanded='false' aria-label='Toggle navigation'>
                            <img src={menu} alt='menu'/>
                        </button>
                        <div className='nav justify-content collapse navbar-collapse' id='menunav'>
                            <ul className='navbar-nav'>
                                <li className='nav-item'>
                                    <a onClick={this.rederigir.bind(this)} className={this.state.tab === 'inicio' ? 'nav-link borde active' : 'nav-link borde'} id='inicio'>INICIO</a>
                                </li>
                                <li className='nav-item'>
                                    <a onClick={this.rederigir.bind(this)} className={this.state.tab === 'acerca-de-nosotros' ? 'nav-link borde active' : 'nav-link borde'} id='acerca-de-nosotros'>ACERCA DE NOSOTROS</a>
                                </li>
                                <li className='nav-item'>
                                    <a id='servicios' className={this.state.tab.split('/')[1] === 'servicios' ? 'nav-link borde active dropdown-toggle' : 'nav-link borde dropdown-toggle'} 
                                        data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' role='button'>SERVICIOS</a>
                                    <div className='dropdown-menu servicios' aria-labelledby='servicios'>
                                        <a onClick={this.rederigir.bind(this)} className='dropdown-item' id='/servicios/mallas-protección-cultivos/antipajaro'>Instalación de mallas para protección de cultivos.</a>
                                        <a onClick={this.rederigir.bind(this)} className='dropdown-item' id='videos'>Obras de infraestructura y servicios generales.</a>
                                    </div>

                                </li>
                                <li className='nav-item'>
                                    <a onClick={this.rederigir.bind(this)} className={this.state.tab === 'galeria/videos' ? 'nav-link borde active' : 'nav-link borde'} id='galería/vídeos'>GALERÍA</a>
                                </li>
                                <li className='nav-item'>
                                    <a href='/#contacto' className={this.state.tab === 'contacto' ? 'nav-link active' : 'nav-link'}>CONTÁCTO</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }
}

export default Navegador