import React from 'react'

import GrupoVideos from './grupovideos'

class Videos extends React.Component {

    render() {
        const proyectos = [
            { titulo: 'Amarra de alambre de anclaje' },
            { titulo: 'Estaba de postes de madera eucalipto' },
            { titulo: 'Instalación de alambre galvanizado' },
            { titulo: 'Instalación de malla cortaviento' },
            { titulo: 'Instalación de malla raschel protección de arenilla salobre' },
            { titulo: 'Instalación de postes de madera' },
            { titulo: 'Malla antipajaro' },
            { titulo: 'Mojado y hoyado del suelo' },
            { titulo: 'Otros' }
        ]

        return (
            <div className='galeria videos'>
                {
                    proyectos.map((proyecto, index) => {
                        return (
                            <div className='wrapper_galeria'>
                                <div className='wrapper_galeria_content'>
                                    <div className='galeria_titulo_content'>
                                        <div className='galeria_titulo'>
                                            <div className='galeria_titulo_header'>
                                                <div className='galeria_titulo_header_content'>
                                                    <h2 className='heading_title'>
                                                        {proyecto.titulo}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <GrupoVideos index={index} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default Videos