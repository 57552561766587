import React from 'react'
import { Router, Route, browserHistory, IndexRoute } from 'react-router'

import './styles.css'

import AuthRoute from './util/AuthRoute'

import { Provider } from 'react-redux'
import store from './redux/store'

import QoxechaApp from './components/qoxechaapp'
import Dashboard from './components/dashboard'

import Nosotros from './components/nosotros/nosotros'
import Mallas from './components/servicios/mallas'

import Videos from './components/galeria/videos'

//axios.defaults.baseURL = "https://us-central1-qoxecha-admin.cloudfunctions.net/web"

class App extends React.Component{
  render (){
    return (
      <Provider store={store}>
        <Router history={browserHistory}>
          <AuthRoute path='/' component={QoxechaApp}>

            <IndexRoute path='' component={Dashboard}/>

            <Route path='acerca-de-nosotros' component={Nosotros} />
            <Route path='servicios/mallas-protección-cultivos/:malla' component={Mallas} />

            <Route path='galería/vídeos' component={Videos} />
            
          </AuthRoute>
        </Router>
      </Provider>
    )
  }
}

export default App