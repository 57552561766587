import {
    LOADING_DATA_ADMIN,
    SET_DATA_ADMIN,
    SET_ERROR_ADMIN,

    LOADING_DATA_SLIDERS,
    SET_DATA_SLIDERS,
    SET_ERROR_SLIDERS,

    LOADING_DATA_SERVICIOS,
    SET_DATA_SERVICIOS,
    SET_ERROR_SERVICIOS,

    LOADING_DATA_NOSOTROS,
    SET_DATA_NOSOTROS,
    SET_ERROR_NOSOTROS,

    LOADING_DATA_PROYECTOS,
    SET_DATA_PROYECTOS,
    SET_ERROR_PROYECTOS
} from '../types'

import axios from 'axios'

export const obtenerWebDatosAdmin = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_ADMIN })
    axios.get('/admin')
        .then((res) => {
            dispatch({
                type: SET_DATA_ADMIN,
                payload: res.data
            })
            console.log(res.data)
            dispatch (obtenerWebSliders ())
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_ADMIN,
                payload: err.data
            })
        })
}

export const obtenerWebSliders = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_SLIDERS })
    axios.get('/sliders')
        .then((res) => {
            dispatch({
                type: SET_DATA_SLIDERS,
                payload: res.data
            })
            console.log(res.data)
            dispatch(obtenerWebServicios())
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_SLIDERS,
                payload: err.data
            })
        })
}

export const obtenerWebServicios = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_SERVICIOS })
    axios.get('/servicios')
        .then((res) => {
            dispatch({
                type: SET_DATA_SERVICIOS,
                payload: res.data
            })
            console.log(res.data)
            dispatch(obtenerWebNosotros())
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_SERVICIOS,
                payload: err.data
            })
        })
}

export const obtenerWebNosotros = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_NOSOTROS })
    axios.get('/nosotros')
        .then((res) => {
            dispatch({
                type: SET_DATA_NOSOTROS,
                payload: res.data
            })
            console.log(res.data)
            dispatch(obtenerWebProyectos())
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_NOSOTROS,
                payload: err.data
            })
        })
}

export const obtenerWebProyectos = () => (dispatch) => {
    dispatch({ type: LOADING_DATA_PROYECTOS })
    axios.get('/proyectos')
        .then((res) => {
            dispatch({
                type: SET_DATA_PROYECTOS,
                payload: res.data
            })
            console.log(res.data)
        }).catch((err) => {
            dispatch({
                type: SET_ERROR_PROYECTOS,
                payload: err.data
            })
        })
}